<template>
  <div class="popup-guide">
    <div class="popup-guide__wrapper">
      <Column :class="$viewport.desktop ? 'grid-6' : ''">
        <Row class="popup-guide__guide"
             justify="between">
          <Column :class="$viewport.desktop ? 'grid-4' : ''">
            <p v-if="$popup.additions.name"
               class="popup-guide__guide-name">{{$popup.additions.name}}</p>
            <p v-if="$popup.additions.annotation"
               class="popup-guide__guide-annotation">{{$popup.additions.annotation}}</p>
            <Row v-if="$popup.additions.site"
                 class="popup-guide__guide-site"
                 align="center">
              <Icon class="popup-guide__guide-site-icon"
                    xlink="site"
                    viewport="0 0 20 20"/>
              <a class="popup-guide__guide-site-text"
                 :href="$popup.additions.site.href">
                {{$popup.additions.site.text}}</a>
            </Row>
            <Row class="popup-guide__guide-meta"
                 justify="btween">
              <Row v-if="$popup.additions.city_id"
                   class="popup-guide__guide-city third"
                   align="center">
                <Icon class="popup-guide__icon-location"
                      xlink="location-20"
                      viewport="0 0 20 20"/>
                {{$cities.data.find(item => item.id === $popup.additions.city_id).name}}
              </Row>
              <div class=" third" v-if="$popup.additions.language">
                {{$popup.additions.language}}
              </div>
              <Rating class=" third"
                      :params="{id: $popup.additions.id,
                      type: 'guides',
                      rating: $popup.additions.rating,
                      currentUserRating: $popup.additions.currentUserRating}"/>
            </Row>
          </Column>
          <Column class="grid-2">
            <div class="popup-guide__guide-avatar-holder">
              <img v-if="$popup.additions.images"
                   class="popup-guide__guide-avatar"
                   :src="$popup.additions.images[0]"
                   alt="">
            </div>
          </Column>
        </Row>
        <Divider color="#cdcdcd"/>
        <div class="popup-guide__about-guide">
          <p class="popup-guide__about-guide-title">О гиде</p>
          <div class="popup-guide__about-guide-description"
               v-html="$popup.additions.description"/>
          <Divider color="#cdcdcd"/>
          <Slider v-if="trips"
                  :indentation="$viewport.desktop ? 30 : 8"
                  :count="1"
                  :items="trips"
                  :height="400"
                  component="Card"
                  limiter/>
          <Divider color="#cdcdcd"/>
          <div class="popup-guide__social-networks-block">
            <p class="popup-guide__social-networks-title">Гид в соцсетях</p>
            <Row v-if="$popup.additions"
                 class="popup-guide__social-networks">
              <div class="popup-guide__social-network">
                <div v-for="(social, index) in $popup.additions.socials"
                     :key="social" class="social-network">
                  <a :href="social"
                     target="_blank">
                    <div :class="`social-network__icon-${index}`"
                         class="social-network__icon">
                      <img :src="require(`@/assets/images/svg/${index}-icon.svg`)"/>
                    </div>
                  </a>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupGuid',
  data() {
    return {
      trips: [],
    };
  },
  created() {
    this.$http.get(`/api/guides/${this.$popup.additions.id}`)
      // eslint-disable-next-line no-return-assign
      .then(resolve => this.trips = resolve.data.data.trips);
  },
};
</script>
